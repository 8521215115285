import React from "react";
import LegalTranscription from "../LegalTranscription/Home";
import Seo from "../components/seo";

function Index() {
  return (
    <>
      <Seo
        title="Legal Transcription Services - LexTranscribe Legal Transcription Company"
        description="Top institutions & law firms trust LexTranscribe’s fast, accurate legal transcription services. Make referencing effortless. Get your free quote now."
      />
      <LegalTranscription />
    </>
  );
}
export default Index;
