import * as React from "react";
import BoxContainer from "../BoxContainer";
import Text from "../Text";
import iconResolver from "../../core/icon-resolver";
import Button from "../Button";
import textResolver from "../../core/utils/text-resolver";

const DoubleCol = ({
  data,
  titleClassName = "font-semibold text-center text-3xl text-blue font-primary pb-10",
  paraClassName = "text-lg leading-8 text-blue font-opensans text-center pb-14",
  className = "flex xs:flex-col md:flex-row gap-6 xs:items-center sm:items-start mb-12 md:mb-0",
  background = "bg-alice-blue",
  background2 = "bg-alice-blue",
  h3class = "text-xl text-blue mb-3 font-primary font-semibold text-[24px] xs:text-center sm:text-left",
  descriptionClass = "text-blue leading-8 font-opensans text-lg xs:text-center sm:text-left xs:mb-5 md:mb-0",
}) => {
  const { dataType = 0 } = data;
  return (
    <>
      <BoxContainer
        background={background}
        className="items-center pt-20 xs:px-5 md:px-8"
      >
        <div className="md:w-10/12 2xl:w-full m-auto 2xl:max-w-6xl">
          <Text
            highlightedText={
              dataType === 1
                ? data.highlightWords
                : textResolver(data, "highlightWords")
            }
            highlightedColor={data.highlightedColor}
            className={`${titleClassName}`}
            as="h2"
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>
          {data.subtitle && (
            <Text as="p" className={paraClassName}>
              {dataType === 1 ? data.subtitle : textResolver(data, "subtitle")}
            </Text>
          )}
        </div>
      </BoxContainer>
      <BoxContainer
        background={background2}
        className="items-center px-5 2xl:px-0"
      >
        <div className="pb-10 my-10 max-w-6xl mx-auto">
          <div className="md:grid grid-cols-2 gap-y-24 gap-x-32">
            {data?.maindata.map((item, i) => (
              <div key={i} className={`${className}`}>
                {item.icon && (
                  <div className="w-min">
                    {iconResolver(
                      dataType === 1 ? item.icon : textResolver(item, "icon")
                    )}
                  </div>
                )}
                <div
                  className={`${
                    item.cta ? "md:h-[450px] lg:h-96 md:pb-6 md:relative" : ""
                  }`}
                >
                  <Text as="h3" className={h3class}>
                    {dataType === 1
                      ? item.heading
                      : textResolver(item, "heading")}
                  </Text>
                  <Text as="div" className={descriptionClass}>
                    {dataType === 1
                      ? item.maintext
                      : textResolver(item, "maintext")}
                  </Text>
                  {item.cta && (
                    <Button
                      title={
                        dataType === 1
                          ? item.cta?.text
                          : textResolver(item.cta, "text")
                      }
                      href={
                        dataType === 1
                          ? item.cta?.href
                          : textResolver(item.cta, "href")
                      }
                      className="w-44 h-[52px] text-base text-center text-white font-opensans font-medium uppercase bg-yellow rounded-full cursor-pointer flex items-center justify-center md:absolute md:bottom-0 md:left-0 mx-auto sm:mx-0 hover:bg-yellowHover transition-colors"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          {data.description && (
            <Text
              as="div"
              className="text-lg leading-8 text-blue font-opensans mt-10 xs:text-center sm:text-left"
            >
              {dataType === 1
                ? data.description
                : textResolver(data, "description")}
            </Text>
          )}
        </div>
      </BoxContainer>
    </>
  );
};
export default DoubleCol;
