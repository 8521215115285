import React from "react";
import Banner from "./Banner";
import CertificationSamples from "../../components/CertificationSamples";
import MainUsp from "./MainUsp";
import DoubleCol from "../../components/DoubleCol";
import CtaWithForm from "./CtaWithForm";
import DoubleColTwo from "./DoubleColTwo";
import Onside from "./Onside";
import Footer from "./Footer";
import {
  legalTranscriptionHeaderdata,
  bannerData,
  bannerSealData,
  certificationSampleData,
  Uspdata,
  doubleColData,
  ctaWithFormData,
  DoubleColTwoData,
  onsidedata,
  ctaWithFormData2,
  legalTranscriptionFooterData,
  contactusPopupData,
  thankYouPopUpData,
} from "../utils/sample-data";

function LegalTranscription() {
  return (
    <>
      <Banner
        data={bannerData}
        headerData={legalTranscriptionHeaderdata}
        sealData={bannerSealData}
        contactusPopUpData={contactusPopupData}
        thankYouPopUpData={thankYouPopUpData}
      />
      <CertificationSamples
        data={certificationSampleData}
        titleClassName="font-bold text-5xl leading-[65px] text-blue font-primary xs:text-center sm:text-left"
      />
      <MainUsp data={Uspdata} />
      <DoubleCol
        data={doubleColData}
        titleClassName="text-5xl leading-[75px] text-blue text-center font-primary font-bold pb-10"
        className="flex xs:flex-col gap-6 xs:items-center sm:items-start mb-12 md:mb-0"
      />
      <CtaWithForm
        data={ctaWithFormData}
        btnStyle="bg-blue hover:bg-prussian-blue-light"
      />
      <DoubleColTwo data={DoubleColTwoData} />
      <Onside data={onsidedata} />
      <CtaWithForm
        data={ctaWithFormData2}
        background="bg-blue"
        btnStyle="bg-yellow hover:bg-lightYellow"
      />
      <Footer data={legalTranscriptionFooterData} />
    </>
  );
}
export default LegalTranscription;
