import BoxContainer from "../../components/BoxContainer";
import React from "react";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
const Onside = ({ data }) => {
  const { dataType = 0 } = data;

  return (
    <BoxContainer
      background="0px:bg-prussian-blue lg:bg-onSite bg-no-repeat bg-cover"
      className="xs:px-5 2xl:px-0 py-20"
    >
      <div
        className="w-full lg:grid lg:grid-cols-2 max-w-6xl mx-auto"
        id="link-orange"
      >
        <div className="invisible xs:hidden lg:block">invisible</div>
        <div className="pr-2">
          <Text
            as="h2"
            highlightedText={
              dataType === 1
                ? data.highlightWords
                : textResolver(data, "highlightWords")
            }
            highlightedColor={data.highlightedColor}
            className="text-3xl text-white font-primary font-semibold text-left pb-9"
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>
          <Text
            as="div"
            className="text-lg leading-8 text-white font-opensans text-left"
          >
            {dataType === 1
              ? data.description
              : textResolver(data, "description")}
          </Text>
        </div>
      </div>
    </BoxContainer>
  );
};

export default Onside;
