import React from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import Button from "../../components/Button";
import textResolver from "../../core/utils/text-resolver";
import OptimizedImage from "../../hooks/OptimizedImage";
import iconResolver from "../../core/icon-resolver";

const MainUsp = ({ data, background = "bg-prussian-blue" }) => {
  const { dataType = 0 } = data;
  return (
    <BoxContainer background={background} className="xs:px-5 2xl:px-0 py-24">
      <div className="w-full max-w-5xl mx-auto">
        <div className="lg:grid lg:grid-cols-2 lg:gap-20 xl:gap-28">
          <div className="hidden lg:block">
            <OptimizedImage
              src={dataType === 1 ? data.imgurl : textResolver(data, "imgurl")}
              className="w-full h-auto"
              alt="image"
            />
          </div>
          <div className="">
            <Text
              as="h2"
              highlightedText={
                dataType === 1
                  ? data.highlightWords
                  : textResolver(data, "highlightWords")
              }
              highlightedColor={data.highlightedColor}
              className="font-primary text-4xl font-bold text-white text-left pb-8"
            >
              {dataType === 1 ? data.title : textResolver(data, "title")}
            </Text>
            <Text
              as="div"
              className="text-basexl text-white text-left font-primary pb-11"
              id="link-orange"
            >
              {dataType === 1
                ? data.description
                : textResolver(data, "description")}
            </Text>
            <div className="flex-col items-start">
              <Button
                // href={data.cta.href}
                // title={
                //   dataType === 1
                //     ? data.cta.text
                //     : textResolver(data.cta, "text")
                // }
                href="/contactus"
                title="get started today"
                className="text-base text-white bg-yellow font-opensans font-medium uppercase px-7 py-3 text-center rounded-full 0px:flex sm:inline-flex items-center justify-center hover:bg-lightYellow transition-colors min-w-[244px] min-h-[56px]"
              />
              <div className="flex gap-x-5 items-center pt-10">
                <div>{iconResolver("lock-white")}</div>
                <div>
                  <Text
                    as="span"
                    className="font-opensans text-xs text-white font-bold pb-2"
                  >
                    {/* {dataType === 1
                      ? data.securityText
                      : textResolver(data, "securityText")} */}
                    Security, Privacy and Confidentiality Guarantee
                  </Text>
                  <div>
                    <Text
                      as="span"
                      className="font-opensans text-[11px] text-slate"
                    >
                      {/* {dataType === 1
                        ? data.securityText2
                        : textResolver(data, "securityText2")} */}
                      Your information is protected by 256-bit SSL encryption.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BoxContainer>
  );
};

export default MainUsp;
